// Libs.
import React from 'react';
import { Router } from '@reach/router';
import { useAuthUserCheckOrError } from '../libs/handleHttpError';
// Components.
import SEO from '../components/layout/seo';
import MemberLayout from '../components/layout/member-layout';
import FMKOrderHistoryDetail from '../components/order-history/fmk-order-history-detail';

const AccountFMKOrderHistoryDetailPage = ({ location }) => {
    useAuthUserCheckOrError();

    return (
        <MemberLayout containered={false}>
            <SEO title="Transaction Detail" />
            <Router location={location} basepath={__BASE_PATH__}>
                <FMKOrderHistoryDetail path="/account-fmk-order-history/detail/:id" />
            </Router>
        </MemberLayout>
    );
};

export default AccountFMKOrderHistoryDetailPage;
